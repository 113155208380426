import {createEventHandler} from '@wix/tpa-settings'
import {EVENTS_APP_ID, EVENT_FILTER_TYPE, ExperimentNames, isRtlLanguage} from '@wix/wix-events-commons-statics'
import {ExperimentsBag} from '@wix/wix-experiments'
import {ControllerParams, CreateControllerFn, TFunction} from '@wix/yoshi-flow-editor'
import {bindActionCreators, PreloadedState, Store} from 'redux'
import {setBaseEnvironment, setFormFactor} from '../../commons/actions/environment'
import {watchInstance} from '../../commons/actions/instance'
import {updateSiteSettings} from '../../commons/actions/site-settings'
import {createUouBiMiddlewareWithBiParams, createUsersBiMiddlewareWithBiParams} from '../../commons/bi/bi'
import {UserRole} from '../../commons/enums'
import {isResponsiveEditor} from '../../commons/selectors/environment'
import {decodeInstance} from '../../commons/selectors/instance'
import {getMultilingualInitialState} from '../../commons/services/multilingual'
import {getQueryParams} from '../../commons/services/navigation'
import {isMembersEventsPageInstalled} from '../../commons/utils/members-api'
import {createReduxStore} from '../../commons/utils/store'
import {getLanguage, getPageInfo, isEditor, isMobile} from '../../commons/utils/wix-code-api'
import {fillSettingsDefaults} from './defaultParams'
import {WidgetSettingsEventsKeys} from './interfaces'
import settingsParams from './settingsParams'
import {
  addCalendarMonth,
  closeMonthlyCalendarEvent,
  closeMonthlyCalendarPopup,
  openMonthlyCalendarEvent,
  openMonthlyCalendarPopup,
  resetCalendar,
  subCalendarMonth,
} from './Widget/actions/calendar-layout'
import {
  initSettings,
  INJECT_COMPONENT_DRAFT,
  updateComponent,
  updateComponentDraft,
  updateStyleParams,
} from './Widget/actions/component'
import {createEvent, shareEvent} from './Widget/actions/event'
import {loadEventsPage, reloadEvents, setFilterType} from './Widget/actions/events'
import {closeListLayoutItems, openListLayoutItem, widgetLoaded} from './Widget/actions/layout'
import {addLoginListener, loadMembersForEvents, openMembersModal} from './Widget/actions/members'
import {navigateToPage, onLinkNavigatedToPage} from './Widget/actions/navigate-to-page'
import {updateSettings, updateStyle} from './Widget/actions/sdk'
import {settingsTabChanged} from './Widget/actions/settings-panel'
import {setVeloCategoryFilter} from './Widget/actions/velo-overrides'
import * as eventsUou from './Widget/bi/uou-bi-events-map'
import * as eventsUsers from './Widget/bi/users-bi-events-map'
import {AppProps} from './Widget/components/app/interfaces'
import {datesMiddleware} from './Widget/middlewares/date'
import reducers from './Widget/reducers'
import {sortEvents} from './Widget/reducers/events'
import {getSelectedEvents} from './Widget/selectors/events'
import {getDynamicCategoryId} from './Widget/selectors/velo-overrides'
import {isPaidPlansInstalled} from './Widget/services/apps'
import {Actions, State, StoreExtraArgs} from './Widget/types/state'
import {userEventsLogger} from './Widget/user-events-logger'
import {Api} from './Widget/utils/api'

const createWidgetController: CreateControllerFn = async (controllerParams: ControllerParams) => {
  const componentEventHandler = createEventHandler(getPublicData(controllerParams))
  const updateConfig = updateConfigHandler(componentEventHandler)
  const experiments = controllerParams.flowAPI.experiments.all()
  const serverApi = new Api(controllerParams)
  const store: any = await createStore({controller: controllerParams, serverApi, experiments})

  return {
    pageReady: async () => {
      await store.dispatch(
        initSettings({
          t: controllerParams.flowAPI.translations.t as TFunction,
          fillSettingsDefaults,
        }),
      )

      pageReady({controllerParams, componentEventHandler, store, serverApi})
    },
    exports: () => ({
      set categoryId(id: string) {
        store.dispatch(setVeloCategoryFilter(id))
        store.dispatch(reloadEvents())
      },
      get categoryId() {
        return getDynamicCategoryId(store.getState())
      },
    }),
    updateConfig: (_, newConfig) => {
      if (store) {
        updateConfig(store, newConfig)
      }
    },
  }
}

interface PageReadyParams {
  controllerParams: ControllerParams
  componentEventHandler: ReturnType<typeof createEventHandler>
  store: any
  serverApi: Api
}

const getPublicData = (controllerParams: ControllerParams) =>
  controllerParams.controllerConfig.config.publicData.COMPONENT || {}

const pageReady = async ({store, serverApi, controllerParams, componentEventHandler}: PageReadyParams) => {
  const {controllerConfig: controller} = controllerParams
  const language = getLanguage(controller.wixCodeApi)

  const [pageInfo, pageUrl] = await Promise.all([
    getPageInfo(controller.wixCodeApi),
    controller.wixCodeApi.site.getSectionUrl({
      sectionId: 'events',
      appDefinitionId: EVENTS_APP_ID,
    }),
  ])
  const state = store.getState()

  const props: Partial<AppProps> = {
    pageUrl,
    baseUrl: controller.wixCodeApi.location.baseUrl,
    queryParams: getQueryParams(controller.wixCodeApi),
    pageInfo,
    state,
    actions: exportedActions({
      store,
    }),
    isRTL: isRtlLanguage(language),
    // @ts-expect-error
    fitToContentHeight: true,
  }

  if (state.membersEnabled) {
    serverApi.onLogin(() => {
      store.dispatch(loadMembersForEvents())
    })
  }

  controller.setProps(props)
  subscribeToStateChanges(controller, store)

  componentEventHandler.on(WidgetSettingsEventsKeys.TabChanged, (info: any) => store.dispatch(settingsTabChanged(info)))
  componentEventHandler.on(WidgetSettingsEventsKeys.InjectComponentDraft, async payload => {
    if (payload) {
      await store.dispatch({
        type: INJECT_COMPONENT_DRAFT,
        payload,
      })
    }

    store.dispatch(reloadEvents())
  })
  componentEventHandler.on(WidgetSettingsEventsKeys.ReloadEvents, async () => {
    await store.dispatch(reloadEvents())
    store.dispatch(setFilterType(controllerParams.flowAPI.settings.get(settingsParams.filterType) as number))
  })
  componentEventHandler.on(WidgetSettingsEventsKeys.HideEvent, (id: string) =>
    store.dispatch(
      updateComponentDraft({
        byEventId: {eventId: getSelectedEvents(store.getState()).filter(eventId => eventId !== id)},
      }),
    ),
  )
}

const updateConfigHandler = (componentEventHandler: ReturnType<typeof createEventHandler>) => {
  return (store: Store<State>, newConfig) => {
    store.dispatch(
      updateStyleParams({
        numbers: newConfig.style.styleParams.numbers,
        booleans: newConfig.style.styleParams.booleans,
      }),
    )

    componentEventHandler.notify(newConfig.publicData.COMPONENT || {})
  }
}

interface CreateStoreParams {
  controller: ControllerParams
  serverApi: Api
  experiments: ExperimentsBag
}

const createStore = async ({controller, serverApi, experiments}: CreateStoreParams) => {
  const {
    controllerConfig: {wixCodeApi, platformAPIs, appParams, compId, config},
    flowAPI,
  } = controller

  let initialData: Awaited<ReturnType<typeof getInitialData>>
  const warmupDataKey = `widget${controller.controllerConfig.compId}`
  if (flowAPI.environment.isSSR && flowAPI.experiments.enabled(ExperimentNames.UseWarmupData)) {
    initialData = await getInitialData({serverApi, controller, experiments})
    wixCodeApi.window.warmupData.set(warmupDataKey, initialData)
  } else {
    initialData =
      wixCodeApi.window.warmupData.get(warmupDataKey) ?? (await getInitialData({serverApi, controller, experiments}))
  }

  const biParams = {
    wixCodeApi,
    platformAPIs,
    appParams,
    compId,
    user: {
      aid: initialData.instance.aid,
      uid: initialData.instance.uid,
    },
  }
  const biMiddleware = [
    createUsersBiMiddlewareWithBiParams(biParams, eventsUsers),
    createUouBiMiddlewareWithBiParams(biParams, eventsUou),
  ]

  const userEventsLoggerMiddleware = userEventsLogger({wixCodeApi})

  const store = createReduxStore<State, StoreExtraArgs>({
    reducers,
    initialData,
    extraArguments: {
      serverApi,
      wixCodeApi,
      compId,
      flowAPI: controller.flowAPI,
    },
    middleware: [...biMiddleware, userEventsLoggerMiddleware, datesMiddleware],
  })

  store.dispatch(updateStyleParams(config.style.styleParams))
  store.dispatch(setBaseEnvironment() as any)
  store.dispatch(addLoginListener() as any)

  watchInstance(controller.controllerConfig, store.dispatch)

  return store
}

const subscribeToStateChanges = (controller: ControllerParams['controllerConfig'], store: Store) => {
  const onStateChange = () => {
    const state: State = store.getState()
    controller.setProps({
      state,
    })
  }

  store.subscribe(onStateChange)
}

interface GetInitialDataParams {
  serverApi: Api
  experiments: ExperimentsBag
  controller: ControllerParams
}

const getInitialData = async ({
  serverApi,
  experiments,
  controller,
}: GetInitialDataParams): Promise<PreloadedState<State>> => {
  const {wixCodeApi, appParams, config: controllerConfig} = controller.controllerConfig
  const {flowAPI} = controller
  const filterType = flowAPI.settings.get(settingsParams.filterType) as number
  const [membersEnabled, paidPlansInstalled] = await Promise.all([
    flowAPI.environment.isADI ? false : isMembersEventsPageInstalled(wixCodeApi),
    flowAPI.environment.isADI ? false : isPaidPlansInstalled(wixCodeApi),
  ])

  const instance = appParams.instance
  const decodedInstance = decodeInstance(instance)
  const isTemplate = decodedInstance.siteIsTemplate
  const responsive = isResponsiveEditor(controllerConfig)
  const showcase = isTemplate || isEditor(wixCodeApi) || isMobile(wixCodeApi)
  const {widgetType, listLayout, recurringFilter} = controllerConfig.style.styleParams.numbers
  const {compId, language, locale, tz, viewMode} = serverApi

  const {
    component: {events = [], config, id},
    siteSettings,
    demoEvents,
    hasMoreEvents,
    dates,
  } = await serverApi.getWidgetData({
    members: membersEnabled,
    paidPlans: paidPlansInstalled,
    responsive,
    showcase,
    widgetType,
    listLayout,
    recurringFilter,
    compId,
    language,
    locale,
    tz,
    viewMode,
    filterType,
  })

  return {
    experiments,
    events: {
      events: filterType === EVENT_FILTER_TYPE.MANUAL ? events : sortEvents(events),
      hasMore: hasMoreEvents,
      moreLoading: false,
      filterType,
    },
    siteSettings,
    demoEvents,
    multilingual: getMultilingualInitialState(wixCodeApi),
    component: {
      ...config,
      id,
    },
    membersEnabled,
    paidPlansEnabled: paidPlansInstalled,
    memberLoggedIn: wixCodeApi.user.currentUser.loggedIn,
    owner: wixCodeApi.user.currentUser.role === UserRole.ADMIN,
    instance: {
      instance,
      ...decodedInstance,
    },
    calendarLayout: {
      ...(dates.calendar ?? {}),
      loadedPages: dates.calendar?.referenceDate ? [dates.calendar.referenceDate] : [],
    },
    dates,
  } as PreloadedState<State>
}

const exportedActions = ({store}) => {
  const dispatchActions = {
    addCalendarMonth,
    subCalendarMonth,
    resetCalendar,
    createEvent,
    updateSiteSettings,
    updateComponent,
    updateComponentDraft,
    navigateToPage,
    onLinkNavigatedToPage,
    updateStyle,
    updateStyleParams,
    updateSettings,
    setBaseEnvironment,
    openListLayoutItem,
    closeListLayoutItems,
    openMonthlyCalendarPopup,
    closeMonthlyCalendarPopup,
    openMonthlyCalendarEvent,
    closeMonthlyCalendarEvent,
    widgetLoaded,
    openMembersModal,
    shareEvent,
    setFormFactor,
    loadEventsPage,
  }

  const actions: Actions = {
    ...bindActionCreators(dispatchActions, store.dispatch),
  }

  return actions
}

export default createWidgetController
